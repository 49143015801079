import React from "react"
const Hero = ({ hero, children }) => {
  return (
    <div class="hero-banner">
      {hero && <img class="hero-image" src={hero} />}
      {children && <div className="hero-content">{children}</div>}
    </div>
  )
}
export default Hero

import * as React from "react"
import "../assets/css/style.css"
import hero from "../assets/images/movinghouse.jpg"
import Layout from "../components/layout"
import Boilerplate from "../components/layout/boilerplate"
import Section from "../components/layout/section"
import Row from "../components/layout/row"
import Container from "../components/layout/container"
import Hero from "../components/surfaces/hero"
import Button from "../components/inputs/button"

const IndexPage = () => {
  return (
    <Layout>
      <Boilerplate>
        <Section>
          <Container>
            <Hero hero={hero}>
              <Button variant="outlined">Sign Up</Button>
              <Button variant="outlined">Find Out More</Button>
            </Hero>
          </Container>
        </Section>
        <Section secondary>
          <Container>
            <h1>What is Veyify?</h1>
            <p>
              Buying and selling property can be a long, laborious and stressful
              process. Maintaining consistent and effective communication with
              vendors, estate agencies, banks, brokers, insurance companies,
              solicitors and surveyors can seem impossible.
            </p>
            <p>
              This is where Veyify steps in - a centralised digital dashboard
              for managing your conveyancing journey. Simply set-up an account,
              create a transaction and add any relavent parties. Once you have
              done this you will be able to add necessary documents, digitally
              sign paperwork and update the progression status of the
              transaction as you navigate through your purchase.
            </p>
            <p>
              No more toing and froing between interested parties. Just one
              simple-to-use platform to handle everything.
            </p>
          </Container>
        </Section>
      </Boilerplate>
    </Layout>
  )
}

export default IndexPage
